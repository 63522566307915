/**
 * The stage to use for API (and websocket) requests.
 */
const STAGE = 'prod';

/**
 * Variables to configure an ITER IDEA's cloud app, together with its inner modules.
 */
export const environment = {
  idea: {
    project: 'brix',
    ionicExtraModules: ['common', 'auth', 'variables'],
    app: { version: '2.9.6', numHoursAfterWhichItemsSyncIsObsolete: 48 },
    api: { url: 'api-brix.iter-idea.com', stage: STAGE },
    socket: { url: 'socket-brix.iter-idea.com', stage: STAGE },
    auth: {
      title: 'Brix',
      website: 'https://iter-idea.com',
      hasIntroPage: false,
      registrationIsPossible: false,
      singleSimultaneousSession: false,
      forceLoginWithMFA: false,
      passwordPolicy: {
        minLength: 8,
        requireLowercase: false,
        requireDigits: false,
        requireSymbols: false,
        requireUppercase: false
      }
    }
  },
  aws: {
    cognito: { userPoolId: 'eu-west-1_SqSlvRHSA', userPoolClientId: '58hb6ev6r5adtdsbqg47nqtii0' }
  }
};
