import { CognitoUser } from 'idea-toolbox';

/**
 * A user in the system.
 */
export class User extends CognitoUser {
  attributes: {
    /**
     * The ID of the shop in which the user works.
     */
    shopId: string;
  };

  validate(): string[] {
    const e = super.validate();
    if (!this.attributes.shopId) e.push('shopId');
    return e;
  }

  /**
   * Whether the user is part of the administrators (or robots) group.
   */
  isAdmin(): boolean {
    return super.isAdmin() || super.isRobot();
  }
}
