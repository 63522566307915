import { Injectable } from '@angular/core';
import { IDEAApiService } from '@idea-ionic/common';

import { Shop } from '@models/shop.model';

@Injectable({ providedIn: 'root' })
export class ShopsService {
  private shops: Shop[] = null;

  constructor(private api: IDEAApiService) {}

  /**
   * Load the list of shops from the back-end.
   */
  private async loadList(): Promise<void> {
    const shops: Shop[] = await this.api.getResource('shops');
    this.shops = shops.map(o => new Shop(o));
  }
  /**
   * Get the list of shop.
   */
  async getList(force = false): Promise<Shop[]> {
    if (!this.shops || force) await this.loadList();
    if (!this.shops) return null;
    return this.shops;
  }

  /**
   * Get a shop by id.
   */
  async getById(shopId: string): Promise<Shop> {
    return new Shop(await this.api.getResource(['shops', shopId]));
  }
}
