import { Injectable } from '@angular/core';
import { Resource } from 'idea-toolbox';
import { IDEAApiService, IDEACachedResourcesService } from '@idea-ionic/common';

import { AppService } from '../app.service';

import { AvailabilityStatuses, Item, ItemConditionStatuses, ItemSummary, ItemTypes } from '@models/item.model';
import { ListPriceOfItem } from '@models/priceList.model';

/**
 * The base URL where the item's images are located.
 */
const ITEMS_IMAGES_BASE_URL = 'https://brix-front-end-media.s3.eu-south-1.amazonaws.com/';
/**
 * The URL where the item's thumbnails are located.
 */
const ITEMS_THUMBNAILS_URL = ITEMS_IMAGES_BASE_URL.concat('thumbnails/items/');
/**
 * The URL where the item's images are located.
 */
const ITEMS_IMAGES_URL = ITEMS_IMAGES_BASE_URL.concat('items/');
/**
 * A local fallback URL for the item's missing images.
 */
const ITEMS_IMAGES_FALLBACK_URL = './assets/imgs/no-image.jpg';

@Injectable({ providedIn: 'root' })
export class ItemsService {
  private items: ItemSummary[] = null;
  private pricePerItemMap: Map<string, number> = null;

  /**
   * The number of items to consider for the pagination.
   */
  MAX_PAGE_SIZE = 24;

  constructor(private crs: IDEACachedResourcesService, private api: IDEAApiService, private app: AppService) {}

  /**
   * Load the list of items from the back-end.
   */
  private async loadList(): Promise<void> {
    const items: ItemSummary[] = await this.api.getResource('items');
    this.items = items.map(x => new ItemSummary(x));
  }

  /**
   * Get (and optionally filter) the list of items.
   * Note: it's a slice of the array.
   */
  async getList(
    options: {
      search?: string;
      filters?: ItemsFilters;
      itemType?: ItemTypes;
      withPagination?: boolean;
      startPaginationAfterId?: string;
    } = { filters: new ItemsFilters() }
  ): Promise<ItemSummary[]> {
    if (!this.items) await this.loadList();
    if (!this.items) return null;

    options.search = options.search ? String(options.search).toLowerCase() : '';
    let filteredList = this.items.slice();

    if (options.filters.categories.length)
      filteredList = filteredList.filter(
        x => !options.filters.categories.length || options.filters.categories.includes(x.categoryId)
      );

    if (options.filters.availability === AvailabilityStatuses.AVAILABLE)
      filteredList = filteredList.filter(x => x.isAvailable(this.app.shop.warehouses));
    else if (options.filters.availability === AvailabilityStatuses.UNAVAILABLE)
      filteredList = filteredList.filter(x => !x.isAvailable(this.app.shop.warehouses));

    if (options.filters.statuses.length)
      filteredList = filteredList.filter(
        x => !options.filters.statuses.length || options.filters.statuses.includes(x.status)
      );

    if (options.filters.condition === ItemConditionStatuses.NOT_PURCHASABLE)
      filteredList = filteredList.filter(x => !this.hasPriceInCurrentList(x));

    if (options.itemType) filteredList = filteredList.filter(x => x.type === options.itemType);

    if (options.search)
      filteredList = filteredList.filter(x =>
        options.search
          .split(' ')
          .every(searchTerm =>
            [x.itemId, x.alternativeId, x.name, x.categoryId]
              .filter(f => f)
              .some(f => f.toLowerCase().includes(searchTerm))
          )
      );

    if (options.withPagination && filteredList.length > this.MAX_PAGE_SIZE) {
      let indexOfLastOfPreviousPage = 0;
      if (options.startPaginationAfterId)
        indexOfLastOfPreviousPage = filteredList.findIndex(x => x.itemId === options.startPaginationAfterId) || 0;
      filteredList = filteredList.slice(0, indexOfLastOfPreviousPage + this.MAX_PAGE_SIZE);
    }

    return filteredList;
  }

  /**
   * Get the full details of an item by its id.
   */
  async getById(id: string): Promise<Item> {
    return new Item(await this.api.getResource(['items', id]));
  }

  /**
   * Get the URL to the item's image by its id.
   */
  getImageURLById(id: string, highQuality?: boolean): string {
    const url = (highQuality ? ITEMS_IMAGES_URL : ITEMS_THUMBNAILS_URL).concat(id, '/', '0.png');
    return this.crs.getCachedResource(url);
  }
  /**
   * Load a fallback URL when the item's image is missing.
   */
  fallbackImage(targetImg: any): void {
    if (targetImg && targetImg.src !== ITEMS_IMAGES_FALLBACK_URL) targetImg.src = ITEMS_IMAGES_FALLBACK_URL;
  }
  /**
   * Search in Lego's DB or Media's DB for an image to replace to the current one for the item.
   */
  async replaceImage(id: string, type: ItemTypes): Promise<void> {
    if (type === ItemTypes.LEGO)
      await this.api.patchResource(['items', id], { body: { action: 'GET_IMAGE_FROM_LEGO_API' } });
    else await this.api.patchResource(['items', id], { body: { action: 'GET_IMAGE_FROM_MEDIA_API' } });
  }
  /**
   * Upload a custom image for the item.
   */
  async uploadCustomImage(id: string, image: File): Promise<void> {
    const { url } = await this.api.patchResource(['items', id], { body: { action: 'GET_IMAGE_UPLOAD_URL' } });
    await fetch(url, { method: 'PUT', body: image, headers: { 'Content-Type': image.type } });
  }

  /**
   * Load and set the desired price list, mapping each item with its price.
   */
  async setPriceListById(id: string): Promise<void> {
    const listPriceOfItems: ListPriceOfItem[] = await this.api.getResource(['priceLists', id, 'items']);

    this.pricePerItemMap = new Map<string, number>();
    listPriceOfItems.forEach(poi => this.pricePerItemMap.set(poi.itemId, poi.price));
  }
  /**
   * Get the price of an item using the current price list.
   */
  getPriceOfItemFromCurrentList(itemOrItemId: Item | ItemSummary | string): number {
    if (!this.pricePerItemMap?.size) return null;

    const itemId = typeof itemOrItemId === 'string' ? itemOrItemId : itemOrItemId?.itemId;
    return this.pricePerItemMap.get(itemId);
  }
  /**
   * Whether the item has a price in the current price list (and so it is purchasable).
   * Note: an item with price equal to 0 is purchasable.
   */
  hasPriceInCurrentList(itemOrItemId: Item | ItemSummary | string): boolean {
    const price = this.getPriceOfItemFromCurrentList(itemOrItemId);
    return price !== undefined;
  }

  /**
   * True if the list of items hasn't been updated (PUT, PATCH) within the last `numHours`.
   */
  arentUpdatedSinceNumHours(numHours: number): boolean {
    if (!this.items?.length) return true;

    const lastUpdateOnItemsInMs: number = Math.max(...this.items.map(i => new Date(i.updatedAt).getTime()));
    const lastUpdatePlusOffsetInMs = lastUpdateOnItemsInMs + numHours * 60 * 60 * 1000;

    return Date.now() > lastUpdatePlusOffsetInMs;
  }

  /**
   * Set the suggested price for the item.
   */
  async setSuggestedPrice(itemId: string, suggestedPrice: number): Promise<Item> {
    return new Item(
      await this.api.patchResource(['items', itemId], { body: { action: 'SET_SUGGESTED_PRICE', suggestedPrice } })
    );
  }
}

/**
 * The attributes used to filter the items list.
 */
export class ItemsFilters extends Resource {
  availability?: AvailabilityStatuses;
  statuses?: string[];
  categories?: string[];
  condition?: ItemConditionStatuses;

  load(x: any = {}): void {
    this.availability = this.clean(x.availability, String, AvailabilityStatuses.ALL);
    this.statuses = this.cleanArray(x.statuses, String);
    this.categories = this.cleanArray(x.categories, String);
    this.condition = this.clean(x.condition, String, ItemConditionStatuses.ALL);
  }

  clear(): void {
    this.load();
  }

  atLeastOneSelected(): boolean {
    return this.availability !== AvailabilityStatuses.ALL || this.statuses.length > 0 || this.categories.length > 0;
  }
}
