import { Resource } from 'idea-toolbox';

import { ItemSummary } from './item.model';
import { Category } from './category.model';

/**
 * A shop (a retailer's office) that sells items.
 */
export class Shop extends Resource {
  /**
   * The ID of the shop. It's the concatenation of `retailerId` and `shopId`.
   */
  shopId: string;
  /**
   * The ID of the retailer.
   */
  retailerId: string;
  /**
   * The ID of the referred retailer's office.
   */
  officeId: string;
  /**
   * The name of the office's retailer.
   */
  retailerName: string;
  /**
   * The name of the office.
   */
  name: string;
  /*
   * The categories of item the shop has access to.
   */
  categories: string[];
  /**
   * The IDs of the warehouses accessible by the shop.
   * If a user is linked to a shop that has no warehouses, they can't purchase anything in the app.
   */
  warehouses: string[];
  /**
   * Whether the shop can see the item's detail quantities.
   */
  canSeeWarehousesQty?: boolean;
  /**
   * The ID of price list to use to value the items.
   * Note: it's usually assigned at Retailer level.
   */
  priceListId: string;
  /**
   * Whether the shop doesn't use the list price for items added to the cart (back-end only flag).
   */
  skipListPriceForCart?: boolean;
  /**
   * Whether the integration with ShippyPRO is active (back-end only flag).
   */
  hasShippyProIntegration?: boolean;

  static getPK(retailerId: string, officeId: string): string {
    return retailerId.concat('_', officeId);
  }

  load(x: any): void {
    super.load(x);
    this.shopId = this.clean(x.shopId, String);
    this.retailerId = this.clean(x.retailerId, String);
    this.officeId = this.clean(x.officeId, String);
    this.retailerName = this.clean(x.retailerName, String);
    this.name = this.clean(x.name, String);
    this.categories = this.cleanArray(x.categories, String);
    this.priceListId = this.clean(x.priceListId, String);
    this.warehouses = this.cleanArray(x.warehouses, String);
    if (x.canSeeWarehousesQty) this.canSeeWarehousesQty = true;
    if (x.skipListPriceForCart) this.skipListPriceForCart = true;
    if (x.hasShippyProIntegration) this.hasShippyProIntegration = true;
  }

  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.shopId = safeData.shopId;
    this.officeId = safeData.officeId;
    this.retailerId = safeData.retailerId;
  }

  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.shopId)) e.push('shopId');
    if (this.iE(this.retailerId)) e.push('retailerId');
    if (this.iE(this.officeId)) e.push('officeId');
    if (this.iE(this.retailerName)) e.push('retailerName');
    if (this.iE(this.name)) e.push('name');
    if (this.iE(this.priceListId)) e.push('priceListId');
    return e;
  }
}

/**
 * Statistics about a shop.
 */
export class ShopStats extends Resource {
  /**
   * The list of newest items.
   */
  newItems: ItemSummary[];
  /**
   * The list of suggested items.
   */
  suggestedItems: ItemSummary[];
  /**
   * The list of the most purchased items.
   */
  mostPurchasedItems: ItemSummary[];
  /**
   * The list of the newly available items.
   */
  newlyAvailableItems: ItemSummary[];
  /**
   * The list of the most purchased categories (based on the purchased items).
   */
  mostPurchasedCategories: Category[];

  load(x: any): void {
    super.load(x);
    this.newItems = this.cleanArray(x.newItems, i => new ItemSummary(i));
    this.suggestedItems = this.cleanArray(x.suggestedItems, i => new ItemSummary(i));
    this.mostPurchasedItems = this.cleanArray(x.mostPurchasedItems, i => new ItemSummary(i));
    this.newlyAvailableItems = this.cleanArray(x.newlyAvailableItems, i => new ItemSummary(i));
    this.mostPurchasedCategories = this.cleanArray(x.mostPurchasedCategories, c => new Category(c));
  }
}
