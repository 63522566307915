import { Resource, epochISOString } from 'idea-toolbox';
import { Shop } from './shop.model';

const DEFAULT_UOM = 'Pz';

/**
 * An item in the catalogue.
 */
export class Item extends Resource {
  /**
   * The ID of the item.
   */
  itemId: string;
  /**
   * The name of the item.
   */
  name: string;
  /**
   * The description of the item.
   */
  description?: string;
  /**
   * The ID of the category in which the item falls into.
   */
  categoryId: string;
  /**
   * The type in which the item falls into.
   */
  type: ItemTypes;
  /**
   * An alternative ID (e.g. EAN barcode) for the item.
   */
  alternativeId?: string;
  /**
   * The current market status of the item.
   */
  status: ItemMarketStatuses;
  /**
   * The number of pieces included in a package.
   */
  numUnitsPerPackage?: number;
  /**
   * The unit of measurement used for the item.
   */
  uom: string;

  /**
   * The detail of the (integer) actual physical inventory in possession for each warehouse (ID).
   */
  qtyInStorageByWarehouse: QuantityByWarehouse;
  /**
   * The total actual physical inventory in possession.
   */
  qtyInStorage: number;
  /**
   * The detail of the (integer) quantity currently allocated by the app for each warehouse (ID).
   */
  qtyAllocatedInAppByWarehouse: QuantityByWarehouse;
  /**
   * The total quantity currently allocated by the app.
   */
  qtyAllocatedInApp: number;
  /**
   * Detail of the quantities ordered, incoming in the next future.
   */
  qtyIncoming: QuantityIncoming[];

  /**
   * The timestamp when the item was created (POST).
   */
  createdAt: epochISOString;
  /**
   * The timestamp when the item was lastly updated (PUT, PATCH).
   * This attribute is used to check whether concurrent edits of the item didn't happen at the same time;
   * therefore it is essential to update it every time we change the item's quantities.
   */
  updatedAt: epochISOString;
  /**
   * The timestamp until the item is considered as newly available.
   */
  showAsNewlyAvailableUntil: epochISOString;
  /**
   * The suggested price of the item.
   */
  suggestedPrice: number;

  load(x: any): void {
    super.load(x);
    this.itemId = this.clean(x.itemId, String);
    this.name = this.clean(x.name, String);
    if (x.description) this.description = this.clean(x.description, String);
    this.categoryId = this.clean(x.categoryId, String);
    this.type = this.clean(x.type, String, ItemTypes.LEGO);
    if (x.alternativeId) this.alternativeId = this.clean(x.alternativeId, String);
    this.status = this.clean(x.status, String, ItemMarketStatuses.IN_PRODUCTION);
    if (x.numUnitsPerPackage) this.numUnitsPerPackage = this.clean(x.numUnitsPerPackage, Number);
    this.uom = this.clean(x.uom, String, DEFAULT_UOM);

    [this.qtyInStorageByWarehouse, this.qtyInStorage] = QuantityByWarehouse.init(
      x.qtyInStorageByWarehouse,
      x.qtyInStorage
    );
    [this.qtyAllocatedInAppByWarehouse, this.qtyAllocatedInApp] = QuantityByWarehouse.init(
      x.qtyAllocatedInAppByWarehouse,
      x.qtyAllocatedInApp
    );
    this.qtyIncoming = this.cleanArray(x.qtyIncoming, i => new QuantityIncoming(i));

    this.createdAt = this.clean(x.createdAt, t => new Date(t).toISOString(), new Date().toISOString());
    this.updatedAt = this.clean(x.updatedAt, t => new Date(t).toISOString(), this.createdAt);
    this.showAsNewlyAvailableUntil = this.clean(x.showAsNewlyAvailableUntil, t => new Date(t).toISOString());
    this.qtyAllocatedInApp = this.clean(x.qtyAllocatedInApp, Number, 0);
    this.suggestedPrice = this.clean(x.suggestedPrice, Number);
  }
  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.itemId = safeData.itemId;

    this.qtyAllocatedInAppByWarehouse = safeData.qtyAllocatedInAppByWarehouse;
    this.qtyAllocatedInApp = safeData.qtyAllocatedInApp;

    this.createdAt = safeData.createdAt;
    this.updatedAt = safeData.updatedAt;
    this.showAsNewlyAvailableUntil = safeData.showAsNewlyAvailableUntil;
    this.suggestedPrice = safeData.suggestedPrice;
  }
  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.name)) e.push('name');
    if (this.iE(this.categoryId)) e.push('categoryId');
    if (!Object.values(ItemTypes).includes(this.type)) e.push('type');
    if (this.iE(this.status)) e.push('status');
    if (this.iE(this.uom)) e.push('uom');
    return e;
  }

  /**
   * Get the quantity in storage.
   */
  getQtyInStorage(warehouses?: string[]): number {
    if (!warehouses) return this.qtyInStorage;
    return warehouses.reduce((tot, warehouse): number => (tot += this.qtyInStorageByWarehouse[warehouse] || 0), 0);
  }
  /**
   * Get the quantity allocated in app.
   */
  getQtyAllocatedInApp(warehouses?: string[]): number {
    if (!warehouses) return this.qtyAllocatedInApp;
    return warehouses.reduce((tot, warehouse): number => (tot += this.qtyAllocatedInAppByWarehouse[warehouse] || 0), 0);
  }

  /**
   * Whether the item is available, based on the calculated quantities.
   */
  isAvailable(warehouses?: string[]): boolean {
    return this.getQtyAvailable(warehouses) > 0;
  }

  /**
   * Calculate the quantity available (inStorage - allocated).
   */
  getQtyAvailable(warehouses?: string[]): number {
    if (!warehouses) return this.qtyInStorage - this.qtyAllocatedInApp;
    return warehouses.reduce(
      (tot, warehouse): number =>
        (tot += (this.qtyInStorageByWarehouse[warehouse] || 0) - (this.qtyAllocatedInAppByWarehouse[warehouse] || 0)),
      0
    );
  }

  /**
   * Calculate the quantity incoming in the future.
   * Note: it doesn't refer to any specific point in time.
   */
  getQtyIncoming(warehouses?: string[]): number {
    if (!warehouses) return this.qtyIncoming.reduce((tot, curr): number => (tot += curr.amount), 0);
    return this.qtyIncoming.reduce(
      (tot, curr): number =>
        (tot += warehouses.reduce(
          (totCurr, warehouse): number => (totCurr += curr.amountByWarehouse[warehouse] || 0),
          0
        )),
      0
    );
  }

  /**
   * Whether the shop can access the category of this item.
   */
  canShopAccess(shop: Shop): boolean {
    return shop?.categories.includes(this.type);
  }
}

/**
 * The possible types of items.
 */
export enum ItemTypes {
  POKEMON = 'POKEMON',
  LEGO = 'LEGO',
  LORCANA = 'LORCANA',
  MEDIA = 'MEDIA',
  'OP/DB' = 'OP/DB'
}

/**
 * A brief representation of an item.
 */
export class ItemSummary extends Resource {
  /**
   * The ID of the item.
   */
  itemId: string;
  /**
   * The name of the item.
   */
  name: string;
  /**
   * The ID of the category in which the item falls into.
   */
  categoryId: string;
  /**
   * The type in which the item falls into.
   */
  type: ItemTypes;
  /**
   * An alternative ID (e.g. EAN barcode) for the item.
   */
  alternativeId?: string;
  /**
   * The current market status of the item.
   */
  status: ItemMarketStatuses;
  /**
   * The unit of measurement used for the item.
   */
  uom: string;

  /**
   * The detail of the (integer) actual physical inventory in possession for each warehouse (ID).
   */
  qtyInStorageByWarehouse: QuantityByWarehouse;
  /**
   * The total actual physical inventory in possession.
   */
  qtyInStorage: number;
  /**
   * The detail of the (integer) quantity currently allocated by the app for each warehouse (ID).
   */
  qtyAllocatedInAppByWarehouse: QuantityByWarehouse;
  /**
   * The total quantity currently allocated by the app.
   */
  qtyAllocatedInApp: number;

  /**
   * The timestamp when the item was created (POST).
   */
  createdAt: epochISOString;
  /**
   * The timestamp when the item was lastly updated (PUT, PATCH).
   */
  updatedAt: epochISOString;
  /**
   * The timestamp until the item is considered as newly available.
   */
  showAsNewlyAvailableUntil: epochISOString;
  /**
   * The suggested price of the item.
   */
  suggestedPrice: number;

  load(x: any): void {
    super.load(x);
    this.itemId = this.clean(x.itemId, String);
    this.name = this.clean(x.name, String);
    this.categoryId = this.clean(x.categoryId, String);
    this.type = this.clean(x.type, String, ItemTypes.LEGO);
    if (x.alternativeId) this.alternativeId = this.clean(x.alternativeId, String);
    this.status = this.clean(x.status, String, ItemMarketStatuses.IN_PRODUCTION);
    this.uom = this.clean(x.uom, String);

    [this.qtyInStorageByWarehouse, this.qtyInStorage] = QuantityByWarehouse.init(
      x.qtyInStorageByWarehouse,
      x.qtyInStorage
    );
    [this.qtyAllocatedInAppByWarehouse, this.qtyAllocatedInApp] = QuantityByWarehouse.init(
      x.qtyAllocatedInAppByWarehouse,
      x.qtyAllocatedInApp
    );

    this.createdAt = this.clean(x.createdAt, String);
    this.updatedAt = this.clean(x.updatedAt, String);
    this.showAsNewlyAvailableUntil = this.clean(x.showAsNewlyAvailableUntil, t => new Date(t).toISOString());
    if (x.suggestedPrice) this.suggestedPrice = this.clean(x.suggestedPrice, Number);
  }

  /**
   * Whether the item is available, based on the calculated quantities.
   */
  isAvailable(warehouses?: string[]): boolean {
    const qtyAvailable = warehouses
      ? warehouses.reduce(
          (tot, warehouse): number =>
            (tot +=
              (this.qtyInStorageByWarehouse[warehouse] || 0) - (this.qtyAllocatedInAppByWarehouse[warehouse] || 0)),
          0
        )
      : this.qtyInStorage - this.qtyAllocatedInApp;
    return qtyAvailable > 0;
  }

  /**
   * Whether the shop can access the category of this item.
   */
  canShopAccess(shop: Shop): boolean {
    return shop?.categories.includes(this.type);
  }
}

/**
 * Detailed information on a quantity incoming in the future.
 */
export class QuantityIncoming extends Resource {
  /**
   * The amount incoming for each warehouse (ID).
   */
  amountByWarehouse: Record<string, number>;
  /**
   * The total amount incoming.
   */
  amount: number;
  /**
   * The estimated arrival date.
   */
  date: epochISOString;

  load(x: any): void {
    super.load(x);
    [this.amountByWarehouse, this.amount] = QuantityByWarehouse.init(x.amountByWarehouse, x.amount);
    this.date = this.clean(x.date, d => new Date(d).toISOString());
  }
}

/**
 * The possible market statuses for an item.
 */
export enum ItemMarketStatuses {
  IN_PRODUCTION = 'IN_PRODUCTION',
  WITHDRAWN = 'WITHDRAWN',
  EXCLUSIVE = 'EXCLUSIVE'
}

/**
 * The possible availability statuses for an item.
 */
export enum AvailabilityStatuses {
  ALL = 'ALL',
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

/**
 * The possible condition statuses for an item.
 */
export enum ItemConditionStatuses {
  ALL = 'ALL',
  NOT_PURCHASABLE = 'NOT_PURCHASABLE'
}

/**
 * Helper class to store a detail of quantity by warehouse ID and its total amount.
 */
export class QuantityByWarehouse {
  [warehouse: string]: number;

  /**
   * Init and clean an object of class QuantityByWarehouse and its total sum.
   */
  static init(inputByWarehouse: Record<string, number>, fallbackQty: number): [Record<string, number>, number] {
    const byWarehouse: Record<string, number> = {};
    let total = 0;
    if (inputByWarehouse) {
      for (const warehouse in inputByWarehouse) {
        if (inputByWarehouse[warehouse]) {
          const value = Math.floor(Number(inputByWarehouse[warehouse]));
          if (value > 0) {
            byWarehouse[warehouse] = value;
            total += value;
          }
        }
      }
    } else total = fallbackQty || 0;
    return [byWarehouse, total];
  }

  /**
   * Check whether the detail by warehouse and the total sum match.
   */
  static checkSum(byWarehouse: Record<string, number>, tot: number): boolean {
    return tot === Object.values(byWarehouse).reduce((y, x): number => (y += x || 0), 0);
  }

  /**
   * Increment the quantity by warehouse for a certain warehouse by some value.
   */
  static increment(byWarehouse: Record<string, number>, warehouse: string, increment: number): void {
    if (!byWarehouse[warehouse]) byWarehouse[warehouse] = 0;
    byWarehouse[warehouse] += increment || 0;
  }
  /**
   * Decrement the quantity by warehouse for a certain warehouse by some value.
   * Note: negative values are aligned to 0.
   */
  static decrement(byWarehouse: Record<string, number>, warehouse: string, decrement: number): void {
    if (!byWarehouse[warehouse]) byWarehouse[warehouse] = 0;
    byWarehouse[warehouse] -= decrement || 0;
    if (byWarehouse[warehouse] < 0) byWarehouse[warehouse] = 0;
  }
}
