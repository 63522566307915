import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Platform, NavController, AlertController } from '@ionic/angular';
import {
  IDEAApiService,
  IDEAStorageService,
  IDEATranslationsService,
  IDEAWebSocketApiService
} from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { AppService } from './app.service';
import { ShopsService } from './shops/shops.service';
import { ItemsService } from './items/items.service';

import { User } from '@models/user.model';

export const authGuard: CanActivateFn = async (): Promise<boolean> => {
  const platform = inject(Platform);
  const navCtrl = inject(NavController);
  const alertCtrl = inject(AlertController);
  const t = inject(IDEATranslationsService);
  const storage = inject(IDEAStorageService);
  const _api = inject(IDEAApiService);
  const _socket = inject(IDEAWebSocketApiService);
  const auth = inject(IDEAAuthService);
  const app = inject(AppService);
  const _shops = inject(ShopsService);
  const _items = inject(ItemsService);

  if (app.authReady) return true;

  //
  // HELPERS
  //

  const showAlertRobotCantSignIn = (): Promise<void> => {
    return new Promise(resolve => {
      const header = t._('IDEA_AUTH.ROBOT_USERS_CANT_SIGN_INTO_APPS');
      const message = t._('IDEA_AUTH.ROBOT_USERS_CANT_SIGN_INTO_APPS_I');
      const backdropDismiss = false;
      const buttons = [{ text: t._('COMMON.GOT_IT'), handler: (): void => resolve() }];
      alertCtrl.create({ header, message, backdropDismiss, buttons }).then(alert => alert.present());
    });
  };

  const doAuth = async (): Promise<void> => {
    const authRes = await auth.isAuthenticated(false, freshIdToken => {
      _api.authToken = freshIdToken;
      _socket.authToken = freshIdToken;
    });

    _api.authToken = authRes.idToken;
    _socket.authToken = authRes.idToken;
    app.user = new User(authRes.userDetails);
    app.shop = await _shops.getById(app.user.attributes.shopId);
  };

  const navigateAndResolve = (navigationPath?: string[]): boolean => {
    if (navigationPath) navCtrl.navigateRoot(navigationPath);
    app.authReady = true;
    return true;
  };

  //
  // MAIN
  //
  if (app.authReady) return true;

  await platform.ready();
  await storage.ready();

  try {
    await doAuth();

    // load the price list to use, based on the user (based on the retailer)
    await _items.setPriceListById(app.shop.priceListId);

    platform.resume.subscribe((): Promise<void> => doAuth());

    if (window.location.pathname === '/') return navigateAndResolve([]);

    return navigateAndResolve();
  } catch (err) {
    switch (err?.message) {
      case 'ROBOT_USER':
        await showAlertRobotCantSignIn();
        return navigateAndResolve(['auth']);
      default:
        return navigateAndResolve(['auth']);
    }
  }
};
