import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { AlertController, NavController, Platform } from '@ionic/angular';
import {
  IDEAActionSheetController,
  IDEAMessageService,
  IDEATranslationsService,
  IDEAStorageService,
  IDEAApiService
} from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { environment as env } from '@env';
import { User } from '@models/user.model';
import { Shop } from '@models/shop.model';

@Injectable({ providedIn: 'root' })
export class AppService {
  initReady = false;
  authReady = false;

  private darkMode: boolean;

  user: User;
  shop: Shop;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private actionSheetCtrl: IDEAActionSheetController,
    private message: IDEAMessageService,
    private storage: IDEAStorageService,
    private auth: IDEAAuthService,
    private api: IDEAApiService,
    private t: IDEATranslationsService
  ) {
    this.darkMode = this.respondToColorSchemePreferenceChanges();
  }
  private respondToColorSchemePreferenceChanges(): boolean {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => (this.darkMode = e.matches));
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  /**
   * Whether we are running the app in developer mode (from localhost).
   */
  isDeveloperMode(): boolean {
    return env.debug;
  }
  /**
   * Open an alert to get the token for running requests against this project's API.
   */
  async getTokenId(): Promise<void> {
    const message = this.api.authToken as string;
    const alert = await this.alertCtrl.create({ message, buttons: ['Thanks 🙌'], cssClass: 'selectable' });
    alert.present();
  }

  /**
   * Whether we should display a UX designed for smaller screens.
   */
  isInMobileMode(): boolean {
    return this.platform.width() < 768;
  }
  /**
   * Whether the current color scheme preference is set to dark.
   */
  isInDarkMode(): boolean {
    return this.darkMode;
  }

  /**
   * Force a reload of the app.
   */
  reloadApp(preservePosition = false): void {
    preservePosition ? window.location.reload() : window.location.assign('');
  }
  /**
   * Navigate to a page by its path.
   */
  goTo(
    path: string[],
    options: { back?: boolean; root?: boolean; queryParams?: Params; animated?: boolean } = { animated: true }
  ): void {
    if (options.back) this.navCtrl.navigateBack(path, options);
    else if (options.root) this.navCtrl.navigateRoot(path, options);
    else this.navCtrl.navigateForward(path, options);
  }
  /**
   * Close the current page and navigate back, optionally displaying an error message.
   */
  closePage(errorMessage?: string, pathBack?: string[]): void {
    if (errorMessage) this.message.error(errorMessage);
    try {
      this.navCtrl.back();
    } catch (_) {
      this.goTo(pathBack || [], { back: true });
    }
  }

  /**
   * Actions on the current user.
   */
  openUserPreferences(): void {
    const header = this.user.name;
    const buttons = [
      { text: this.t._('COMMON.LOGOUT'), icon: 'log-out', handler: () => this.logout() },
      { text: this.t._('COMMON.CANCEL'), role: 'cancel', icon: 'arrow-undo' }
    ];
    this.actionSheetCtrl.create({ header, buttons }).then(actions => actions.present());
  }
  /**
   * Sign-out from the current user.
   */
  private logout(): void {
    const header = this.t._('COMMON.LOGOUT');
    const message = this.t._('COMMON.ARE_YOU_SURE');
    const buttons = [
      { text: this.t._('COMMON.CANCEL') },
      {
        text: this.t._('COMMON.LOGOUT'),
        handler: () => this.auth.logout().finally(() => this.storage.clear().then(() => window.location.assign('')))
      }
    ];
    this.alertCtrl.create({ header, message, buttons }).then(alert => alert.present());
  }

  /**
   * Utility to generate a numeric array.
   * Useful for skeleton interfaces.
   */
  generateNumericArray(length: number): number[] {
    return [...Array(length).keys()];
  }

  /**
   * Convert an array to a CSV string.
   */
  arrayToCSV(
    array: any[],
    opts: { addHeader: boolean; separator: string } = { addHeader: false, separator: ',' }
  ): string {
    const fieldSeparator = opts.separator;
    const lineSeparator = '\r\n';

    let str = '';

    if (opts.addHeader) str += Object.keys(array[0]).join(fieldSeparator) + lineSeparator;

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += fieldSeparator;
        line += array[i][index];
      }
      str += line + lineSeparator;
    }

    return str;
  }
  /**
   * Convert an array to a CSV file and download it.
   */
  downloadArrayAsCSVFile(array: any[], opts: { filename: string; separator?: string }): void {
    const csvContent = this.arrayToCSV(array, { addHeader: true, separator: opts.separator });
    const csvData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', opts.filename);
    tempLink.click();
  }
}
