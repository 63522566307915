import { Resource } from 'idea-toolbox';
import { ItemTypes } from './item.model';

export class Category extends Resource {
  /**
   * The ID of the category.
   */
  categoryId: string;
  /**
   * The name of the category.
   */
  name: string;
  /**
   * The type in which the category falls into.
   */
  type: ItemTypes;
  /**
   * Whether the category should be somehow highlighted.
   */
  starred: boolean;

  load(x: any): void {
    super.load(x);
    this.categoryId = this.clean(x.categoryId, String);
    this.name = this.clean(x.name, String);
    this.type = this.clean(x.type, String, ItemTypes.LEGO);
    this.starred = this.clean(x.starred, Boolean);
  }
  safeLoad(newData: any, safeData: any): void {
    super.safeLoad(newData, safeData);
    this.categoryId = safeData.categoryId;
  }
  validate(): string[] {
    const e = super.validate();
    if (this.iE(this.name)) e.push('name');
    if (!Object.values(ItemTypes).includes(this.type)) e.push('type');
    return e;
  }
}
