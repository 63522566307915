import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { initGuard } from './init.guard';
import { authGuard } from './auth.guard';
import { adminGuard } from './admin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'stats',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: (): Promise<any> => import('@idea-ionic/auth').then(m => m.IDEAAuthModule),
    canActivate: [initGuard]
  },
  {
    path: 'stats',
    loadChildren: (): Promise<any> => import('./shops/stats/stats.module').then(m => m.StatsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'dashboard',
    loadChildren: (): Promise<any> => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'items',
    loadChildren: (): Promise<any> => import('./items/item.module').then(m => m.ItemModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'orders',
    loadChildren: (): Promise<any> => import('./orders/orders.module').then(m => m.OrdersModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'cart',
    loadChildren: (): Promise<any> => import('./cart/cart.module').then(m => m.CartModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'wishlist',
    loadChildren: (): Promise<any> => import('./wishlist/wishlist.module').then(m => m.WishlistModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'ships',
    loadChildren: (): Promise<any> => import('./ships/ships.module').then(m => m.ShipsModule),
    canActivate: [initGuard, authGuard, adminGuard]
  },
  {
    path: 'chat',
    loadChildren: (): Promise<any> => import('./chat/chat.module').then(m => m.ChatModule),
    canActivate: [initGuard, authGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
